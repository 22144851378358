var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "b-row",
        { staticClass: "mb-3" },
        [
          _c(
            "b-col",
            [
              _c(
                "b-alert",
                {
                  staticClass: "mt-3",
                  attrs: { variant: "warning", show: _vm.showAlertSuccess },
                },
                [
                  _vm._v(
                    "\n\t\t\t\t" +
                      _vm._s(
                        _vm.FormMSG(
                          60,
                          "An e-mail containing a validation code has just been sent to"
                        ) +
                          " " +
                          _vm.emailValue
                      ) +
                      "\n\t\t\t"
                  ),
                ]
              ),
              _c(
                "b-alert",
                {
                  staticClass: "mt-3",
                  attrs: { variant: "info", show: _vm.showAlertError },
                },
                [_vm._v("\n\t\t\t\t" + _vm._s(_vm.msgModalError) + "\n\t\t\t")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm.hiddenInput && _vm.loadingFor.verifyEmail
        ? _c(
            "b-row",
            [
              _c(
                "b-col",
                { staticClass: "text-center" },
                [
                  _c("b-spinner", {
                    attrs: { label: "Loading...", small: "" },
                  }),
                  _vm._v(
                    " " + _vm._s(_vm.FormMSG(146, "Send code validation")) + " "
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _c(
        "div",
        { staticClass: "password-forgotten-form" },
        [
          !_vm.hiddenInput
            ? _c(
                "b-row",
                { staticClass: "mb-3" },
                [
                  _c(
                    "b-col",
                    { attrs: { cols: _vm.$screen.width <= 576 ? 12 : 8 } },
                    [
                      _c(
                        "b-input-group",
                        { staticClass: "mb-3" },
                        [
                          _c(
                            "b-input-group-prepend",
                            [
                              _c(
                                "b-input-group-text",
                                [
                                  _c(_vm.getLucideIcon("User"), {
                                    tag: "component",
                                    attrs: {
                                      color: _vm.ICONS.PERCENT.color,
                                      size: 18,
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c("b-form-input", {
                            staticClass: "form-control",
                            class: {
                              "is-invalid": _vm.$v.email.$error,
                            },
                            staticStyle: {
                              "border-radius": "0px 8px 8px 0px !important",
                            },
                            attrs: {
                              type: "email",
                              placeholder: _vm.FormMSG(103, "Email address"),
                              "aria-describedby": "input-email-feedback",
                            },
                            on: { input: _vm.handleInputEmail },
                            model: {
                              value: _vm.email,
                              callback: function ($$v) {
                                _vm.email = $$v
                              },
                              expression: "email",
                            },
                          }),
                          _vm.$v.email.$error
                            ? _c("div", { staticClass: "invalid-feedback" }, [
                                _vm._v(
                                  "\n\t\t\t\t\t\t" +
                                    _vm._s(
                                      _vm.FormMSG(170, "Email incorrect")
                                    ) +
                                    "\n\t\t\t\t\t"
                                ),
                              ])
                            : _vm._e(),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "b-col",
                    { attrs: { cols: _vm.$screen.width <= 576 ? 12 : 4 } },
                    [
                      _c(
                        "b-button",
                        {
                          attrs: {
                            variant: "primary",
                            block: "",
                            disabled:
                              _vm.disableVerifyEmail ||
                              _vm.loadingFor.verifyEmail ||
                              _vm.codeVerified,
                          },
                          on: { click: _vm.verifyEmail },
                        },
                        [
                          _vm.dataToCheck.emailCodeValidation !== 0 &&
                          !_vm.loadingFor.verifyEmail
                            ? _c("span", [
                                _vm._v(
                                  "\n\t\t\t\t\t\t" +
                                    _vm._s(_vm.FormMSG(45, "Resend code")) +
                                    "\n\t\t\t\t\t"
                                ),
                              ])
                            : _vm._e(),
                          !_vm.loadingFor.verifyEmail &&
                          _vm.dataToCheck.emailCodeValidation === 0
                            ? _c("span", [
                                _vm._v(
                                  " " +
                                    _vm._s(_vm.FormMSG(46, "Verify email")) +
                                    " "
                                ),
                              ])
                            : _vm._e(),
                          _vm.loadingFor.verifyEmail
                            ? _c(
                                "div",
                                {
                                  staticClass:
                                    "d-flex justify-content-center align-items-center",
                                },
                                [
                                  _c("b-spinner", {
                                    attrs: { label: "Loading...", small: "" },
                                  }),
                                  _c(
                                    "div",
                                    {
                                      staticClass: "pl-2",
                                      staticStyle: { "margin-top": "1px" },
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(_vm.FormMSG(47, "Check email"))
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                        ]
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm.$screen.width <= 576 && _vm.showVerificationMail
            ? _c("p", [
                _vm._v(
                  "\n\t\t\t" +
                    _vm._s(_vm.FormMSG(53, "Enter email verfication code")) +
                    "\n\t\t"
                ),
              ])
            : _vm._e(),
          _vm.showVerificationMail
            ? _c(
                "b-row",
                { staticClass: "mb-3" },
                [
                  _c("b-col", { attrs: { cols: "8" } }, [
                    _c(
                      "fieldset",
                      {
                        staticClass: "form-group",
                        attrs: {
                          horizontal: true,
                          id: "fieldset-email-validation",
                          "aria-labelledby": "aria-phone-validation",
                        },
                      },
                      [
                        _c("div", { staticClass: "form-row" }, [
                          _vm.$screen.width >= 471
                            ? _c(
                                "legend",
                                {
                                  staticClass:
                                    "col-8 bv-no-focus-ring col-form-label",
                                  attrs: {
                                    tabindex: "-1",
                                    id: "aria-email-validation",
                                  },
                                },
                                [
                                  _vm._v(
                                    "\n\t\t\t\t\t\t\t" +
                                      _vm._s(
                                        _vm.FormMSG(
                                          53,
                                          "Enter email verfication code"
                                        )
                                      ) +
                                      " :\n\t\t\t\t\t\t"
                                  ),
                                ]
                              )
                            : _vm._e(),
                          _c(
                            "div",
                            { staticClass: "col" },
                            [
                              _c("b-form-input", {
                                ref: "codeValidation",
                                attrs: {
                                  state: _vm.stateError.emailCodeValidation,
                                },
                                on: {
                                  input: _vm.handleInputEmailCodeValidation,
                                },
                                model: {
                                  value: _vm.emailCodeValidation,
                                  callback: function ($$v) {
                                    _vm.emailCodeValidation = $$v
                                  },
                                  expression: "emailCodeValidation",
                                },
                              }),
                            ],
                            1
                          ),
                        ]),
                      ]
                    ),
                  ]),
                  _vm.stateError.emailCodeValidation !== null &&
                  _vm.stateError.emailCodeValidation === false
                    ? _c(
                        "b-col",
                        { staticClass: "pt-2", attrs: { cols: "4" } },
                        [_c("X", { attrs: { color: "#B11548", size: 20 } })],
                        1
                      )
                    : _vm._e(),
                  _vm.stateError.emailCodeValidation === true
                    ? _c(
                        "b-col",
                        {
                          class: _vm.$screen.width <= 576 ? "pt-2" : "pt-2",
                          attrs: { cols: "4" },
                        },
                        [
                          _c("Check", {
                            attrs: { color: "#28a745", size: 20 },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
      _c(
        "b-modal",
        {
          attrs: {
            "header-class": "header-class-modal-doc-package",
            title: _vm.FormMSG(62, "Validation code"),
            "ok-only": "",
            "ok-variant": "success",
            "ok-title": _vm.FormMSG(61, "Ok"),
            "no-close-on-esc": "",
            "no-close-on-backdrop": "",
          },
          on: { ok: _vm.codeValidationFocus },
          model: {
            value: _vm.showModalSuccess,
            callback: function ($$v) {
              _vm.showModalSuccess = $$v
            },
            expression: "showModalSuccess",
          },
        },
        [
          _vm._v(
            "\n\t\t" +
              _vm._s(
                _vm.forResetPasswordUser === false
                  ? _vm.FormMSG(
                      60,
                      "An e-mail containing a validation code has just been sent to"
                    ) +
                      " " +
                      _vm.email
                  : _vm.FormMSG(
                      173,
                      "Please, enter the code sent to your email, that allow you to access on the next page, and enter your new password."
                    )
              ) +
              "\n\t"
          ),
        ]
      ),
      _c(
        "b-modal",
        {
          staticClass: "modal-success",
          attrs: {
            "header-class": "header-class-modal-doc-package",
            title: _vm.FormMSG(35652, "Validation code"),
            "ok-variant": "success",
            "ok-title": _vm.FormMSG(126, "Ok"),
            "no-close-on-esc": "",
            "no-close-on-backdrop": "",
            "hide-header-close": "",
            "ok-only": "",
          },
          model: {
            value: _vm.showModalError,
            callback: function ($$v) {
              _vm.showModalError = $$v
            },
            expression: "showModalError",
          },
        },
        [_vm._v("\n\t\t" + _vm._s(_vm.msgModalError) + "\n\t")]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }